import mitt from 'mitt';
import type { NotificationData } from './use-notification-manager';

export const notificationEmitter = mitt();

export const showNotificationKey = 'show-notification';

export function sendShowNotification(data: NotificationData) {
  notificationEmitter.emit(showNotificationKey, data);
}
